import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FaSpotify,
  FaApple,
  FaSoundcloud,
  FaYoutube,
  FaBandcamp,
} from "react-icons/fa"
import albumImg from "../images/late-blooming-leo.jpg"
import gPlayIcon from "../images/google-play-icon.svg"
import cdBabyIcon from "../images/cd-baby-icon.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Late Blooming Leo - San Francisco Folk Music" />
    <div id="home-page">
      <img className="album-img" src={albumImg} alt="album art"/>
      <h1>Late Blooming Leo</h1>
      <p>
        A San Francisco based folk band. Currently available to listen to on the
        following platforms.
      </p>
      <div className="platform-list">
        <a
          className="platform-list-item"
          href="https://open.spotify.com/artist/7b3YZoErgXSdhMqJ0fngll?si=N6kBBQuGTz2Q1qfuN4_1pA"
        >
          <FaSpotify alt="spotify icon"/>
          <span>Spotify</span>
        </a>
        <a
          className="platform-list-item"
          href="https://itunes.apple.com/us/artist/1416215909"
        >
          <FaApple alt="apple music icon"/>
          <span>Apple Music</span>
        </a>
        <a
          className="platform-list-item"
          href="https://play.google.com/music/m/Arzrqxvujpau7cgjixcm5sabt54?t=Late_Blooming_Leo"
        >
          <img src={gPlayIcon} alt="google music icon"/>
          <span>Google Play Music</span>
        </a>
        <a
          className="platform-list-item"
          href="https://soundcloud.com/latebloomingleo"
        >
          <FaSoundcloud alt="soundcloud icon"/>
          <span>SoundCloud</span>
        </a>
        <a
          className="platform-list-item"
          href="https://store.cdbaby.com/Artist/LateBloomingLeo"
        >
          <img src={cdBabyIcon} alt="cd baby icon"/>
          <span>CD Baby</span>
        </a>

        <a
          className="platform-list-item"
          href="https://www.youtube.com/watch?v=oX0a1pTDZKU&list=PLrnTdzcvbb_EuTn9D_CeYiFTppFJ1q4jO"
        >
          <FaYoutube alt="youtube icon"/>
          <span>YouTube</span>
        </a>

        <a
          className="platform-list-item"
          href="http://latebloomingleo.bandcamp.com/"
        >
          <FaBandcamp alt="bandcamp icon"/>
          <span>Bandcamp</span>
        </a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
